import React from "react"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"

import Seo from "~/components/seo"
import PageHero from "~/components/page-hero"
import SectionTitle from "~/components/section-title"
import Container from "~/components/container"

import useAboutPage from "~/hooks/use-about-page"

export default function About() {
  const { page, sketch } = useAboutPage()
  const { pageHero, heading, bodyText, bubbles } = page

  const bubbleSizeIndex = [
    "row-start-1 md:col-start-2 md:row-start-2 lg:row-start-1 -mr-10 md:-mt-36 md:-mr-28 lg:mt-0 lg:-mr-24 lg:-ml-14",
    "row-start-2 col-start-2 md:row-start-1 md:col-start-4 lg:row-start-4 lg:col-start-2 -mt-20 ml-4 -mr-4 md:mt-10 md:-ml-12 lg:-mt-20 lg:ml-20 lg:-mr-48",
    "row-start-1 col-start-3 md:row-start-2 md:col-start-5 lg:col-start-2 mt-5 mr-10 md:-mt-10 md:-ml-10",

    "hidden lg:block lg:row-start-5 lg:col-start-3 -ml-32 mt-10 mr-5",
    "hidden md:block row-start-1 col-start-1 lg:row-start-6 -mr-10 -mt-2 lg:ml-10 lg:-mr-32 lg:mt-0",

    "hidden lg:block lg:row-start-5 -mr-10 -mt-10",
    "hidden md:block col-start-6 row-start-1 lg:col-start-3 lg:row-start-6 mt-4 -ml-5 mr-10",
  ]

  const renderImageBubble = (image, i) => {
    return (
      <div key={image.id} className={bubbleSizeIndex[i]}>
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.title}
          className="w-full h-auto"
        />
      </div>
    )
  }

  return (
    <>
      <Seo
        title="About"
        description="HeartBee Flowers is run by me, Julia. I only discovered my
                passion for flowers two years ago but have since spent all my
                time obsessing over them - taking courses online and working
                in a beautiful florist shop in Banff, Canada."
        image={`https:${getSrc(bubbles.images[0])}`}
      />

      <PageHero
        title={pageHero.title}
        className=""
        image={pageHero.coverImage.gatsbyImageData}
      />

      <section>
        <Container>
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-12 gap-8 lg:gap-10">
            <div className="text-center xl:col-start-2 xl:col-span-6">
              <SectionTitle
                title={heading}
                classes={["py-4 px-8 mb-8 lg:mb-12"]}
              />

              <div
                className="prose text-left lg:prose-xl"
                dangerouslySetInnerHTML={{
                  __html: bodyText.childMarkdownRemark.html,
                }}
              />
            </div>

            <div className="xl:col-span-4 row-start-1 lg:row-start-auto">
              <div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-3 max-w-sm md:max-w-2xl mx-auto">
                {bubbles.images.map(renderImageBubble)}
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center">
            <GatsbyImage
              image={sketch}
              className="mt-10 mx-auto w-48 transform -rotate-45"
              alt="HeartBee Flowers · Winchester"
            />
          </div>
        </Container>
      </section>
    </>
  )
}
