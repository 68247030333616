import { graphql, useStaticQuery } from "gatsby"

const useAboutPage = () => {
  const data = useStaticQuery(graphql`
    {
      page: contentfulPageAbout {
        pageHero {
          title
          coverImage {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        bodyText {
          childMarkdownRemark {
            html
          }
        }
        bubbles {
          images {
            id
            title
            gatsbyImageData(quality: 70, width: 300, layout: FULL_WIDTH)
          }
        }
      }
      sketch: contentfulAsset(title: { eq: "craspedia" }) {
        gatsbyImageData(
          width: 400
          layout: CONSTRAINED
          placeholder: TRACED_SVG
        )
      }
    }
  `)

  return {
    page: data.page,
    sketch: data.sketch.gatsbyImageData,
  }
}

export default useAboutPage
